const buttons = {
  'Recovery button': 'Відновити',
  'Change button': 'Змінити',
  'Back button': 'Назад',
  'Create button': 'Створити',
  'Save button': 'Зберегти',
  'Update button': 'Оновити',
  'Delete button': 'Видалити',
  'Search button': 'Знайти',
  'Apply button': 'Застосувати',
  'Clear button': 'Очистити',
  'Copy button': 'Копіювати',
  'Cancel button': 'Скасування',
  'Ok button': 'Ok',
  'Back to Home': 'Повернутися на головну',
  'status-city': 'Инфо',
  'cancel-city': 'Скасування',
  'register-city': 'Зареєструвати'
};

const menu = {
  'Transaction menu': 'Транзакції',
  'Users menu': 'Користувачі',
  'Role menu': 'Ролі',
  'User Item': 'Інформація про користувача',
  'Banks menu': 'Банки',
  'Flow menu': 'Схеми',
  'Gateway menu': 'Шлюз',
  'Cascading menu': 'Каскад',
  'Cascading rules menu': 'Правила',
  'Cascading models menu': 'Моделі',
  'Terminals menu': 'Термінали',
  'Merchant menu': 'Мерчанти',
  'Description menu': 'Експорт документів',
  'Description menu custom': 'Експорт',
  'Description menu city24': 'Экспорт city24',
  'Codes menu': 'Коди',
  'Bin menu': 'Bin',
  'Reconciliation menu': 'Звірка'
};

const text = {
  'Having an account': 'У мене вже є обліковий запис',
  'Reset account password': 'Відновити доступ',
  'Success update': 'Запись обновлена',
  'Success upload': 'Файл загружен',
  'Do you want to remove': 'Бажаєте видалити запис?',
  'Register account': 'Реєстрація',
  'Success registration': 'Ви успішно зареєстровані',
  'Success deleted': 'Запис видалено',
  'Reset title': 'Відновлення паролю',
  'Success recovery send token': 'Інструкцію надіслано на email',

  'Transactions List': 'Список транзакцій',
  'Transactions Confirmation': 'Справка транзакций',
  'Transactions Item': 'Інформація про транзакцію',

  'Users List': 'Список користувачів',
  'User Item Update': 'Оновлення користувача',
  'User Item Create': 'Створення користувача',

  nonExistent: 'Відсутній у ПО',
  missing: 'Відсутній у звіті',
  resp_code: 'Статус (код)',
  duplicate: 'Дублікат',

  reconciliationId: 'ІД звірки',
  reconciliationJobName: 'Тип Звірки',
  createOn: 'Дата',
  merchantId: 'Мерчант',
  fieldName: 'Поле звірки',
  reason: 'Статус',
  reference: 'Значення поля',
  originalField: 'Значення в системі',
  reportField: 'Значення в звіті',
  resolved: 'Виправлено',
  'Roles List': 'Список ролей',
  'Role Item Create': 'Створення ролі',
  'Role Item Update': 'Оновлення ролі',
  reconciliationResult: 'Результат звірки',
  fixed: 'Виправити',

  'Terminals List': 'Список терміналів',
  'Terminals Item Id': 'Інформація про термінал',
  'Terminals Model Create': 'Створення нового терміналу',
  'Terminals Token Update': 'Оновлення ключів',
  'Terminals Item Update': 'Оновлення терміналу',
  'Terminal Item Id': 'Інформація про термінал',
  'Do you want to update token': 'Ви дійсно бажаєте оновити ключ?',

  'Merchant List': 'Список мерчантів',
  'Merchant Item Id': 'Опис мерчанта',
  'Merchant Item Update': 'Оновлення мерчанта',
  'Merchant Item Create': 'Створення мерчанта',
  'Merchant Token Update': 'Оновлення ключа мерчанта',

  'Banks List': 'Список банків',
  'Banks Item Id': 'Інформація про банк',
  'Bank Item Create': 'Додавання нового банку',
  'Bank Item Update': 'Оновлення банку',
  'Bank Deposit Update': 'Оновлення ліміту',

  'Transactions Flow List': 'Список схем транзакцій',
  'Transactions Flow Create': 'Створення схеми транзакції',

  'Gateway List': 'Список шлюзів',
  'Gateway Item Create': 'Створення шлюзу',
  'Gateway Item': 'Опис шлюзу',
  'Gateway Item Update': 'Оновлення шлюзу',

  'Cascading Rules List': 'Список правил',
  'Cascading Models List': 'Список моделей',
  'Cascading Model Create': 'Створення моделі',

  'Codes List': 'Список кодів',
  'Codes Item Id': 'Інформація про код',
  'Code Item Update': 'Оновлення коду',
  'Code Item Create': 'Створення коду',
  'Transactions Logs Item': 'Логи транзакцій',
  'Reconciliation List': 'Звірка',
  miss_in_bnk: 'Відсутня у банку',
  miss_in_pt: 'Відсутня у paytech',
  trn_not_registered: 'Не зареєстровано',
  miss_in_bnk2: 'Увага!!!',
  dateStart: 'Період з',
  dateEnd: 'Період до',
  tranTypeId: 'Тип операції',
  bankId: 'Банк',
  respCode: 'Статус',
  amountFrom: 'Сума від',
  amountTo: 'Сума до',
  'card first 6 number': 'Перші 6 цифр картки',
  'card last 4 number': 'Останні 4 цифри картки',
  tranId: 'ID транзакції',
  gateway: 'Банк',
  acsStatus: 'Статус 3DS',
  approval: 'Код авторизації',
  callbackUrl: 'Call back URL',
  fee: 'Комісія',
  getewayRefNo: 'ID банку',
  lang: 'Мова',
  pan: 'Картка',
  respCodeId: 'Код відповіді',
  rrn: 'RRN',
  tranType: 'Тип операції',
  description: 'Опис',
  status: 'Статус',
  timezone: 'Тимчасова зона',
  comment: 'Коментарі',
  bankName: 'Банк',
  fields: 'Інші поля',
  langEn: 'Англійська',
  langRu: 'Російська',
  langUk: 'Українська',
  external: 'Код',
  merchId: 'Мерчант',
  Profile: 'Профіль',
  Settings: 'Налаштування',
  Logout: 'Вийти',
  Balance: 'Баланс',

  'Export List': 'Експорт файлів',
  generated_reports_list: 'Список доступних файлів',
  'City24 Files List': 'Експорт файлов city24',
  'Download File': 'Завантажити файл',
  'Bin List': 'Bin',
  'Invitation link': 'Посилання для запрошення користувачів',

  'Success tab': 'Успешные',
  'Failed tab': 'Отклоненные',
  'Conversion tab': 'Конверсия',

  'Password was successfully changed': 'Пароль был успешно изменен',
  'Reset success send':
    'Инструкция по восстановлению пароля была отправлена на указанный email'
};

const fields = {
  sortAscending: 'За зростанням',
  sortDescending: 'По спаданню',
  orderBy: 'Сортувати за',
  uuid: 'ID',
  name: 'Назва',
  createOn: 'Створено',
  merchant: 'Мерчант',
  tranId: 'ID транзакції',
  tranType: 'Тип операції',
  pan: 'Номер картки',
  amount: 'Сума',
  fee: 'Комісія',
  gateway: 'Еквайр',
  respCode: 'Код відповіді',
  lang: 'Мова',
  editOn: 'Оновлено',
  firstName: "Ім'я",
  lastName: 'Прізвище',
  phone: 'Телефон',
  loginTries: 'Спроб авторизації',
  lastLogin: 'Дата авторизації',
  linkToken: 'Ключ',
  role: 'Роль',
  'email table': 'Email',
  field_email: 'Email',
  'role name field': 'Роль',
  'permissions field': 'Дозволи',
  'name bank field': 'Назва банку',
  depositLimit: 'Ліміт депозиту',
  keyToken: 'Ключі',
  flowName: 'Назва схеми',
  endpoint: 'Точка входу',
  env: 'Оточення',
  bank: 'Банк',
  'name gateway field': 'Назва шлюзу',
  'Select value': 'Вибрати значення',
  notificationChannel: 'Канал нотифікації',
  type_operation: 'Тип операції',
  'Set deposit limit': 'Установить',
  'Increase deposit limit': 'Увеличить',
  'Decrease deposit limit': 'Уменьшить',
  'Comment show': 'Комментарий',
  Loading: 'Загрузка',
  'Upload file': 'Загрузить файл',
  cityRespCode: 'City24 статус',
  'Request success send': 'Запрос успешно отправлен',
  company_email: 'Email компанії',
  company_id: 'ID компанії'
};

const server = {
  'Token error': 'Неправильний ключ',
  '404 error title': 'Сторінку не знайдено',
  '404 error description':
    'Сторінку не знайдено. Швидше за все вона була видалена або переміщена :('
};

const validationForm = {
  required: "Поле обов'язкове для заповнення",
  email: 'Поле має бути поштовою скринькою',
  'Passwords must match': 'ППаролі не співпадають',
  'Error password format':
    'У паролі має бути щонайменше 8 символів. Використовуйте великі та малі літери, а також цифри та спецсимволи',
  'Error phone format': 'Телефон має бути у форматі 380XXXXXXXXX',
  'field float': 'Поле должно быть целым или дробным числом',
  'field number': 'Поле должно быть целым числом'
};

export const ua = {
  'Forgot password': 'Забули пароль?',
  'Create new account': 'Створити акаунт',
  'Login text': 'Авторизація',
  ...buttons,
  ...fields,
  ...text,
  ...validationForm,
  ...menu,
  ...server
};
